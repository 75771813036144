import {notNullish} from 'quickstart/types'
import * as R from 'rambdax'
import {MetaObject, api, dom, logger, toLongId, toTizraId} from 'tizra'
import {SparseField, asOptions} from '../types'

const log = logger('search/config/field-types/titleGroup')

type TitleGroupField = SparseField<string, true> & {
  collectionId?: string
  includeMetaTypes: string[] | null
  excludeMetaTypes: string[]
}

type MiniMetaObject = Pick<MetaObject, 'name' | 'metaType' | 'tizraId'> & {
  _expanded?: true
}

type OptionWithResults = {
  value: string
  text: string
  _results: MiniMetaObject[]
}

type OptionWithValues = {
  value: string
  text: string
  _values: string[]
}

export const titleGroup: TitleGroupField = {
  label: 'Publications',
  includeMetaTypes: ['Book'],
  excludeMetaTypes: ['PdfPage', 'PageRange', 'toc-entry'],
  options: field => {
    const {collectionId, includeMetaTypes, excludeMetaTypes} =
      field as TitleGroupField
    if (!collectionId) {
      log.error('titleGroup field requires collectionId')
      return Promise.resolve([])
    }

    const contents = (tizraId: string) =>
      api
        .search({
          filterCollectionId: tizraId,
          any: (includeMetaTypes || []).map(m => `metaType:${m}`),
          excluded: (excludeMetaTypes || []).map(m => `metaType:${m}`),
          fields: ['name', 'tizra-id', 'meta-type'],
          page: 1000,
        })
        .then(({result, size}) => {
          if (result.length < size) {
            log.warn(
              `collection ${tizraId} size (${size}) exceeds page (${result.length})`,
            )
          }
          return result
            .filter(notNullish)
            .map(item =>
              (item as MiniMetaObject).tizraId === tizraId ?
                {...item, _expanded: true}
              : item,
            ) as MiniMetaObject[]
        })

    const optionWithResults = (item: MiniMetaObject): OptionWithResults => ({
      value: item.tizraId,
      text: item.name,
      _results: [item],
    })

    const isCollection = (item: MiniMetaObject) =>
      /collection/i.test(item.metaType)

    const expandItem = (item: MiniMetaObject) => {
      if (!isCollection(item)) {
        return [item]
      } else if (item._expanded) {
        log.debug?.(`skipping already-expanded ${item.tizraId} (${item.name})`)
        return [item]
      }
      log.debug?.(`expanding collection ${item.tizraId} (${item.name})`)
      return contents(item.tizraId).then(results => {
        const expandedResults: MiniMetaObject[] = [
          // Keep item in the list of results, but mark it as having been
          // expanded.
          {...item, _expanded: true},
          // Drop item if it also appears in the expanded list of results.
          ...results.filter(r => r.tizraId !== item.tizraId),
        ]
        return expandedResults
      })
    }

    const expandResults = (
      results: MiniMetaObject[],
    ): Promise<MiniMetaObject[]> =>
      Promise.all(results.map(expandItem)).then(
        (expandedResults: MiniMetaObject[][]) =>
          R.piped(
            expandedResults.flat().sort((a, b) =>
              a._expanded === b._expanded ? 0
              : a._expanded ? -1
              : 1,
            ),
            R.uniqBy(item => item.tizraId),
          ),
      )

    const expandOption = (o: OptionWithResults): Promise<OptionWithResults> =>
      expandResults(o._results).then(er => ({...o, _results: er}))

    const expandOptions = (options: OptionWithResults[]) =>
      Promise.all(options.map(expandOption))

    const optionWithValues = ({
      _results,
      ...o
    }: OptionWithResults): OptionWithValues => ({
      ...o,
      _values: _results.map(o => o.tizraId),
    })

    const sanityCheck = (options: OptionWithResults[]) => {
      options.forEach(({value: tizraId, text: name, _results}) => {
        const nested = _results.filter(isCollection).filter(r => !r._expanded)
        if (nested.length) {
          log.error(
            `nested collection ${tizraId} (${name}) contains nested collections whose contents will not be searched:\n` +
              nested.map(r => `${r.tizraId} (${r.name})`).join('\n'),
          )
        }
      })
      return options
    }

    return contents(collectionId)
      .then(R.map(optionWithResults))
      .then(expandOptions)
      .then(expandOptions)
      .then(sanityCheck)
      .then(R.map(optionWithValues))
  },
  filterTags: {
    label: 'within',
  },
  // Omit meta-type (implies AdminTagged) since ids are unique.
  metaTypes: [],
  prop: 'titleGroup',
  ensureConfigFields: true,
  hooks: {
    defaultValue: ({field}) => {
      const metaType = dom.metaTagValue('tizra-meta-type')
      const tizraId = dom.metaTagValue('tizra-id') || ''
      if (!metaType || !tizraId || metaType === 'SiteDisplay') {
        return []
      }
      const options = asOptions(field.options) as OptionWithValues[]
      // Look for exact match, otherwise look for collections containing.
      const found = options.find(o => o.value === tizraId)
      if (found) {
        return [found.value]
      }
      return options.flatMap(o =>
        o._values.includes(tizraId) ? [o.value] : [],
      )
    },
    hits: ({field, hits}) => {
      const tizraIdHits = Object.fromEntries(
        hits.map(h => [toTizraId(parseInt(h.value)), h.count]),
      )
      const options = asOptions(field.options) as OptionWithValues[]
      return options.map(o => ({
        value: o.value,
        count: o._values
          .map(v => tizraIdHits[v] || 0)
          .reduce((sum, count) => sum + count, 0),
      }))
    },
  },
  api: {
    // This doesn't use filter-title-group because that is limited to
    // a single id. Instead we implement the same way as
    // filter-title-group works in the server, by passing the list of
    // ids as the titleGroup prop.
    contribute: ({
      field,
      field: {options: _options},
      options: {hittingOn},
      value,
      utils: {all, log, propParam},
    }) => {
      const options = asOptions(_options) as OptionWithValues[]
      if (
        hittingOn === field.name &&
        log.assert(!value.length, `hittingOn ${field.name} with value?`)
      ) {
        // Normally when fetching hits for a field via prop-values, we
        // omit its value from the query so as to receive all the
        // possible values and counts in return. But with titleGroup this
        // could be a very long list, so explicitly request the titles
        // that are interesting to us.
        value = options.map(o => o.value)
      }
      return all(
        propParam(
          field,
          R.piped(
            value,
            R.chain(v => options.find(o => o.value === v)?._values || []),
            R.uniq, // multiple collections with the same publication
            R.map(toLongId),
          ),
        ),
      )
    },
  },
}
