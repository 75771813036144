import {SparseField} from '../types'

export const sort: SparseField<string, false> = {
  api: {
    // after instead of contribute, so this runs after terms.api.contribute
    after: ({
      chat: {sortMetaTypes = []},
      config: {sorting},
      options: {sort: shouldSort},
      sp: {...sp},
      utils: {log, metaTypesInheritFrom},
      value,
    }) => {
      if (!shouldSort) {
        // Ordinary search should be sorted, but calls like prop-values do not
        // need the sort parameters.
        return
      }

      // Convert -descending suffix to invert-order.
      if (value.endsWith('-descending')) {
        sp.invertOrder = true
      }
      value = value.replace(/-(?:ascending|descending)$/, '')

      // 1. Look for a simple string:
      //      sorting.date = 'DatePublished'
      const sortProp = sorting[value]
      if (typeof sortProp === 'string') {
        if (sortProp === '') {
          // In the sorting object, a value of '' means relevance.
          // In the API, not passing sort-prop means relevance.
        } else {
          sp.sortProp = sortProp
        }
        return {sp}
      }

      // 2. Look for a per-metaType sort:
      //      sorting.Book.date = 'DatePublished'
      for (const metaType of sortMetaTypes) {
        const perMetaTypeSortProp =
          sortProp[metaType] ||
          (metaTypesInheritFrom(metaType, 'Book') && sortProp['Book'])
        if (typeof perMetaTypeSortProp !== 'string') {
          continue
        }
        if (perMetaTypeSortProp === '') {
          // In the sorting object, a value of '' means relevance.
          // In the API, not passing sort-prop means relevance.
        } else {
          sp.sortProp = perMetaTypeSortProp
        }
        return {sp}
      }

      // 3. Give up
      log.error(`don't know how to sort ${sortMetaTypes} by ${value}`)
    },
  },
}
