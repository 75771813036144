import {SearchConfig} from 'quickstart/lib/search/config'
import {parseParams, unparseParams} from 'quickstart/lib/url-params'
import * as R from 'rambdax'
import {useMemo} from 'react'
import {Location, Path, useNavigate} from 'react-router-dom'
import {ensureArray, logger, semiJoin, tryJson} from 'tizra'
import {useLocation} from './useLocation'

const log = logger('useSearchParams')

const locationToPath: (loc: Location) => Path = R.pick([
  'pathname',
  'search',
  'hash',
])

export interface SetParamsOptions {
  method?: 'push' | 'replace'
  location?: Partial<Path> | ((loc: Partial<Path>) => Partial<Path>)
}

export const useSearchParamsMap = (
  config: SearchConfig,
  prefix = config.mode === 'browse' ? 'browseParam-' : 'searchParam-',
) => {
  const [mapParamsIn, mapParamsOut] = useMemo(() => {
    const mapParamsIn: Record<
      string,
      | string
      | ((
          v: string | null,
          usp: URLSearchParams,
        ) => Record<string, string> | null)
    > = {}

    // Classic params first (lowest priority).
    if (config.mode === 'search') {
      mapParamsIn.context = v => (v === '-1' ? {within: '-1'} : null)
      mapParamsIn.searchText = 'terms'
      mapParamsIn.searchMode = () => null
    }

    // JSON params second. https://github.com/Tizra/cubchicken/issues/648#issuecomment-1613825810
    // https://library.triprinceton.org/~searchResults?search.params=%7B%22Book--Authors--string-list%22%3A%5B%22Samuel+Gourion-Arisquaud%22%2C%22Samuel+Gourion%E2%80%90Arsiquaud%22%2C%22Samuel+Gourion-Arsiquaud%22%5D%2C%22Book--ItemType--keyword-list%22%3A%5B%22Research+papers+and+magazine+articles%22%5D%2C%22terms%22%3A%22samuel%22%7D
    if (config.mode === 'search') {
      mapParamsIn['search.params'] = v => {
        if (!v) return null
        const w = R.piped(
          tryJson<Record<string, unknown>>(v, e =>
            log.error('Failed to parse search.params JSON', e),
          ) || {},
          R.map((v: unknown, _k: string) =>
            typeof v === 'string' ? v
            : Array.isArray(v) ? semiJoin(v)
            : typeof v === 'object' ? JSON.stringify(v)
            : `${v}`,
          ),
        )
        return w
      }
    }

    // Prefixed params third. This includes searchParam-terms for
    // compatibility.
    Object.assign(
      mapParamsIn,
      Object.fromEntries(
        Object.keys(config.fields)
          .sort()
          .map(k => [`${prefix}${k}`, k]),
      ),
    )

    // Special params fourth (highest priority).
    // We use s instead of searchParam-terms because this is a query parameter
    // that GA4 recognizes without special configuration.
    if (config.mode === 'search') {
      mapParamsIn.s = 'terms'
    }

    // Reverse inbound mapping for outbound. Later entries override earlier, so
    // we get terms->s instead of terms->searchParam-terms.
    const mapParamsOut: Record<string, string> = R.piped(
      mapParamsIn,
      R.toPairs,
      R.filter(([_k, v]) => typeof v === 'string'),
      R.map(([k, v]) => [v, k] as [string, string]),
      R.fromPairs, // Later entries override earlier
      // Now sort remaining keys for stable tests and location bar.
      R.toPairs,
      R.sortBy(R.head) as typeof R.identity,
      R.fromPairs,
    ) as Record<string, string>

    return [mapParamsIn, mapParamsOut] as const
  }, [config, prefix])

  return [mapParamsIn, mapParamsOut] as const
}

/**
 * React hook for getting and setting search parameters.
 */
export const useSearchParams = (config: SearchConfig, prefix?: string) => {
  const current = locationToPath(useLocation())
  const navigate = useNavigate()
  const [mapParamsIn, mapParamsOut] = useSearchParamsMap(config, prefix)

  const [params, setParams] = useMemo(() => {
    const usp = new URLSearchParams(current.search)

    // Use mapParamsIn to map query string keys to params keys.
    const rawParams: Record<string, string> = {}
    for (const [queryKey, fieldKey] of Object.entries(mapParamsIn)) {
      const v = usp.get(queryKey)
      if (typeof fieldKey === 'function') {
        Object.assign(rawParams, fieldKey(v, usp))
      } else if (v !== null) {
        rawParams[fieldKey] = v
      }
    }

    // Parse params according to types.
    const params = parseParams(rawParams, config)

    const setParams = (
      params: Record<string, unknown>,
      {location: override, method = 'push'}: SetParamsOptions = {},
    ) => {
      // Delete compat and prefixed keys to start fresh.
      for (const k of Object.keys(mapParamsIn)) {
        usp.delete(k)
      }

      // Unparse typed params to string params for assignment back to query.
      // This will omit anything where the value matches the default.
      const rawParams = unparseParams(params, config)
      for (const [rk, qk] of Object.entries(mapParamsOut)) {
        if (rk in rawParams) {
          for (const v of ensureArray(rawParams[rk])) {
            usp.append(qk, v)
          }
        }
      }
      const missing = R.difference(R.keys(rawParams), R.keys(mapParamsOut))
      if (missing.length) {
        log.warn('missing params in mapParamsOut:', missing)
      }

      // Assign back to router.
      let newLocation: Partial<Path> = {
        hash: current.hash,
        pathname: current.pathname,
        ...override,
        search: usp.toString(),
      }
      if (typeof override === 'function') {
        newLocation = override(newLocation)
      }
      if (!R.equals(current, {...current, ...newLocation})) {
        navigate(newLocation, {replace: method === 'replace'})
      }
    }

    return [params, setParams] as const
  }, [config, current, mapParamsIn, mapParamsOut, navigate])

  return [params, setParams] as const
}
