import {Falsish, Opt} from 'quickstart/types'
import {dom, PropDef} from 'tizra'

export const CONSTRAINTS = [
  'all', // filled from params
  'any', // quickSearchFields or metaType restrictions
  'excluded', // not used
] as const

type Constraint = (typeof CONSTRAINTS)[number]

export const STAGES = [
  'initialize',
  'before',
  'contribute',
  'after',
  'cleanup',
] as const

type Stage = (typeof STAGES)[number]

export type SearchDepth = 'fulltext' | 'metadata' | 'toc'

// TODO kill indirection
export type ServerDefinition = PropDef

export interface FieldDefinition extends ServerDefinition {
  metaType: string
}

export interface MergedDefinition extends FieldDefinition {
  combinedName: string
  metaTypes: string[]
}

// SP is search params eventually passed to the API.
export type SP = {[k in Constraint]: string[]} & {
  filterCollectionId?: string
  filterLicenses?: true
  invertOrder?: true
  snippetProp?: string
  snippetQuery?: string
  sortProp?: string
  page?: number
  start?: number
}

export interface SearchParams {
  terms?: string
  [k: string]: unknown
}

export interface SearchParamsOptions {
  allowEmpty?: boolean
  boost?: boolean
  hittingOn?: string
  logName?: string
  metaTypes?: string[]
  paging?: boolean
  snippet?: boolean
  sort?: boolean
  tokens?: any // ???
}

// incomplete
type ContributeUtils<T, MULTI extends boolean> = {
  [k in Constraint]: (
    ...args: Array<string | Falsish>
  ) => undefined | {[kk in k]: string[]}
} & {
  log: Record<string, (...args: any) => any>
  metaTypesInheritFrom: (...args: any) => any // TODO
  propParam: (
    field: SearchField<T, MULTI>,
    value: unknown,
  ) => undefined | string
  toTerms: (...args: any[]) => string // TODO
  toTokens: (terms: string) => any[] // TODO
}

interface ContributeProps<T, MULTI extends boolean> {
  chat: Record<string, any>
  config: SearchConfig
  field: SearchField<T, MULTI>
  metaTypes: string[]
  my: any
  name: string
  options: Required<SearchParamsOptions>
  params: SearchParams
  sp: SP
  utils: ContributeUtils<T, MULTI>
  value: MULTI extends true ? T[]
  : MULTI extends false ? T
  : T | T[]
}

type ContributeRet = Partial<SP> & {
  chat?: Record<string, any>
  haveProps?: boolean
  haveTerms?: boolean
  metaTypes?: string[]
  my?: unknown
  params?: SearchParams
  sp?: SP
}

// null return has special meaning distinct from undefined.
type Contribute<T, MULTI extends boolean> = (
  props: ContributeProps<T, MULTI>,
) => ContributeRet | null | undefined

export type SearchFieldOptions<T = string> = Array<{value: T; text: string}>

export const isOptions = <T = string>(x: any): x is SearchFieldOptions<T> =>
  Array.isArray(x) && x[0]?.value !== undefined

export const asOptions = <T = string>(x: any) => (isOptions<T>(x) ? x : [])

export interface ValueParameterizedSearchField<
  T = unknown,
  MULTI extends boolean = boolean,
> {
  name: string
  // Everything past here is optional.
  abbrev?:
    | boolean
    | ((v: T, f: ValueParameterizedSearchField<T, MULTI>) => Falsish | string)
  adjuncts?: string[]
  api?: {[k in Stage]?: Contribute<T, MULTI>}
  defaultOpen?: boolean
  defaultValue?: MULTI extends true ? T[]
  : MULTI extends false ? T
  : T | T[]
  ensureConfigFields?: boolean
  id?: string
  format?: (v: T, f: ValueParameterizedSearchField<T, MULTI>) => string
  hits?: boolean
  hooks?: {
    defaultValue?: (props: {
      field: ValueParameterizedSearchField<T, MULTI>
    }) => MULTI extends true ? T[] | undefined
    : MULTI extends false ? T | undefined
    : T | T[] | undefined
    hits?: (props: {
      field: ValueParameterizedSearchField<T, MULTI>
      hits: Array<{value: string; count: number}>
    }) => Array<{value: string; count: number}>
  }
  key?: string
  label?:
    | string
    | ((v: T, f: ValueParameterizedSearchField<T, MULTI>) => string)
  loading?: boolean
  maxRender?: number
  metaType?: string
  metaTypes?: string[]
  multi?: MULTI extends boolean ? MULTI : boolean
  options?:
    | string[]
    | Array<{value: T; text?: string}>
    | ((
        f: ValueParameterizedSearchField<T, MULTI>,
      ) => Array<{value: T; text?: string}>)
    | ((f: ValueParameterizedSearchField<T, MULTI>) => Promise<any>)
  placeholder?: string
  processOptions?: (x: any, f: ValueParameterizedSearchField<T, MULTI>) => any
  promise?:
    | Promise<any>
    | ((f: ValueParameterizedSearchField<T, MULTI>) => Promise<any>)
  prop?: string
  reverse?: boolean
  serverDef?: MergedDefinition
  show?: boolean | ((f: ValueParameterizedSearchField<T, MULTI>) => boolean)
  sort?: boolean | 'magic'
  test?: (v: T, f: ValueParameterizedSearchField<T, MULTI>) => boolean
  tips?: string
  type?: string
  widget?: 'checklist' | 'input' | 'radio' | 'radio-inline' | 'select'
}

export interface SearchField<T = unknown, MULTI extends boolean = boolean>
  extends Omit<ValueParameterizedSearchField<T, MULTI>, 'label'> {
  label?: string
  // Context-dependent overrides.
  advancedSearch?: Partial<ValueParameterizedSearchField<T, MULTI>>
  desktopFilters?: Partial<ValueParameterizedSearchField<T, MULTI>>
  filterTags?: Partial<ValueParameterizedSearchField<T, MULTI>>
  mobileFilters?: Partial<ValueParameterizedSearchField<T, MULTI>>
  browseFilters?: Partial<ValueParameterizedSearchField<T, MULTI>>
}

export type SearchFields<NullField = never> = Record<
  string,
  SearchField | NullField
>

export interface SearchConfig<NullField = never> {
  bookmarkable: boolean
  dom: {
    hideFooter: () => dom.HiddenObject[]
    showFooter: (hidden: dom.HiddenObject[]) => void
  }
  fieldDefs: FieldDefinition[]
  mode: 'browse' | 'quick' | 'search'
  metaTypes: {
    metadata: string[]
    toc?: 'toc-entry'[]
    fulltext?: string[]
  }
  depths: SearchDepth[]
  fields: SearchFields<NullField>
  ignoreProps?: string[]
  onlyExplicitFields?: boolean
  order: string[]
  pre?: {
    any?: string | string[]
    all?: string | string[]
    excluded?: string | string[]
    filterCollectionId?: string
  }
  sorting: Record<string, string | Record<string, string>>
  defaultOpen: boolean | Array<number | string>
  quickSearchFields: string[]
  autoComplete?: {
    enablePhrases?: boolean
    enableStops?: boolean
    minChars?: number
    readonly maxResults?: number
    readonly delay?: number
    unquotedPhraseHack?: boolean
  }
}

export type SparseField<T = unknown, MULTI extends boolean = boolean> = Partial<
  SearchField<T, MULTI>
>

export interface SearchConfigWithSparseFields
  extends Opt<Omit<SearchConfig, 'fields'>, 'fieldDefs'> {
  fields: Record<string, SparseField | null>
}

export type SparseConfig = Partial<SearchConfigWithSparseFields>
