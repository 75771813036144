import * as R from 'rambdax'

/**
 * Sort items alphabetically, ignoring stuff that doesn't matter.
 */
export function magicSort<T>(key: (x: T) => any, arr: T[]): T[]
export function magicSort<T>(key: (x: T) => any): (arr: T[]) => T[]
export function magicSort<T>(key: (x: T) => any, ...args: any) {
  const _magicSort = (arr: T[]) => {
    const result = arr.slice()
    const keyString = (x: T) => {
      const k = key(x)
      return R.isNil(k) ? '' : `${k}`
    }
    result.sort((a, b) => {
      const aa = keyString(a)
      const bb = keyString(b)
      return aa.localeCompare(bb, undefined, {
        sensitivity: 'base',
        ignorePunctuation: true,
        numeric: true,
      })
    })
    return result
  }
  return args.length ? _magicSort(args[0]) : _magicSort
}

/**
 * Sort items by item.sortField, using the same algorithm as used by the Tizra
 * server concerning bangs. This is stable, so it should run after magicSort.
 */
export const sortBySortField = <T extends object>(arr: T[]): T[] =>
  arr.slice().sort((a: any, b: any) => {
    const asf = `${a.sortField ?? ''}`
    const bsf = `${b.sortField ?? ''}`
    return (
      (asf[0] === '!' && bsf[0] !== '!' && -1) ||
      (bsf[0] === '!' && asf[0] !== '!' && 1) ||
      (asf < bsf && -1) ||
      (bsf < asf && 1) ||
      0
    )
  })
