/**
 * Functions for translating params to/from URL query string
 */

import * as R from 'rambdax'
import {isPlainObject, logger, semiJoin, semiSplit, stableJson} from 'tizra'
import {SearchField} from './search/config'

const log = logger('url-params')

const type = (v: unknown) =>
  typeof v === 'string' ? 'string'
  : Number.isInteger(v) ? 'integer'
  : Array.isArray(v) ? 'array'
  : isPlainObject(v) ? 'object'
  : R.isNil(v) ? 'null'
  : null

type MinimalSearchField = Pick<SearchField, 'defaultValue'>

/**
 * Decode value according to type of field.defaultValue.
 */
const fieldDecoder =
  (fields: Record<string, MinimalSearchField>) =>
  (value: any, name: string) => {
    const dt = type(fields[name].defaultValue)
    try {
      switch (dt) {
        case 'string':
          return value
        case 'integer': {
          const pv = parseInt(value, 10)
          return Number.isNaN(pv) ? null : pv
        }
        case 'array':
          return semiSplit(value)
        case 'object':
          return JSON.parse(value)
        case 'null':
          return value
        default:
          log.error(`field ${name} (${dt}) can't be decoded`)
      }
    } catch (e) {
      log.logger('fieldDecoder').error(`${name}=`, value, e)
    }
  }

/**
 * Encode value according to type of value.
 */
const fieldEncoder =
  (fields: Record<string, MinimalSearchField>) =>
  (value: any, name: string): string | null | undefined => {
    const vt = type(value)
    const dt = type(fields[name].defaultValue)

    log.assert(vt === dt, `type mismatch: value ${vt} vs defaultValue ${dt}`)

    try {
      switch (vt) {
        case 'string':
          return value as string
        case 'integer':
          return `${value}`
        case 'array':
          return semiJoin(value)
        case 'object':
          return stableJson(value)
        case 'null':
          return null
        default:
          log.error(`field ${name} (${typeof value}) can't be encoded`)
      }
    } catch (e) {
      log.logger('fieldEncoder').error(`${name}=`, value, e)
    }
  }

/**
 * Translate from raw string params to config-informed params.
 */
export const parseParams = (
  rawParams: Record<string, string>,
  {fields}: {fields: Record<string, MinimalSearchField>}, // normally SearchConfig
) => {
  const decode = fieldDecoder(fields)

  const params = R.piped(
    rawParams,
    R.pick(R.keys(fields)),
    R.map(decode),
    R.filter((v: unknown, k: string) => !R.equals(v, fields[k]?.defaultValue)),
    R.filter((v: unknown, _k: string) => !R.isNil(v)),
  )

  return params
}

/**
 * Translate from config-informed params to raw string params.
 */
export const unparseParams = (
  params: Record<string, unknown>,
  {fields}: {fields: Record<string, MinimalSearchField>}, // normally SearchConfig
) => {
  const encode = fieldEncoder(fields)

  const rawParams = R.piped(
    params,
    R.pick(R.keys(fields)),
    R.filter((v: unknown, k: string) => !R.equals(v, fields[k]?.defaultValue)),
    R.map(encode),
    R.filter((v: string | null | undefined, _k: string) => !R.isNil(v)),
  ) as Record<string, string>

  return rawParams
}
