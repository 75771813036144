import {QueryClient} from '@tanstack/react-query'
import {AdminContextFromServer} from 'quickstart/types'
import {Nullish} from '../types'
import {defaultOptions} from './private'

const adminQueryClients = new WeakMap<WeakKey, QueryClient>()

export const getAdminQueryClient = ({
  key,
}: {
  context: AdminContextFromServer
  key?: WeakKey | Nullish
}) => {
  let queryClient = key && adminQueryClients.get(key)
  if (!queryClient) {
    queryClient = new QueryClient({defaultOptions})
    if (key) {
      adminQueryClients.set(key, queryClient)
    }
  }
  return queryClient
}
