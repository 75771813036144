import {metaTypesInheritFrom} from 'quickstart/lib/search/config'
import {runHook} from 'quickstart/lib/search/hooks'
import {toTerms, toTokens} from 'quickstart/lib/search/terms'
import * as R from 'rambdax'
import {colonJoin, logger, mapToObj, semiJoin, sort, stableJson} from 'tizra'
import {
  CONSTRAINTS,
  SP,
  STAGES,
  SearchConfig,
  SearchParamsOptions,
} from './config/types'

const log = logger('search/search-params')
const {browser} = log

// any/all/excluded shortcuts
const shortcuts = mapToObj(
  c =>
    (...args: string[]) => {
      args = args.filter(Boolean)
      if (args.length) {
        return {[c]: args}
      }
    },
  CONSTRAINTS,
)

const sortedKeys = (obj: object) => Object.keys(obj).sort()

const push = (xs: string[], add?: string[]) =>
  void (add && xs.splice(xs.length, 0, ...add))

export const BOOST_FIELD_NAME = 'BoostSearchTop'

export default function searchParams(
  config: SearchConfig,
  params: Record<string, unknown>,
  options: SearchParamsOptions = {},
) {
  const {fields, pre} = config
  log.debug?.({fields, options, params, pre})

  // Default options.
  options = {
    allowEmpty: false,
    paging: false,
    snippet: false,
    sort: false,
    ...options,
  }

  // Check for params that aren't represented by fields.
  const names = sortedKeys(fields)
  const adjuncts = R.compose(
    sort,
    R.flatten,
    R.reject(R.isNil),
    R.map(R.prop('adjuncts')),
    R.values,
  )(fields)
  const knownNames = R.compose(sort, R.concat(adjuncts), R.keys)(fields)
  const paramNames = R.filter(R.test(/^[^\W_]/), sortedKeys(params))
  const unknownNames = R.difference(paramNames, knownNames)
  unknownNames.forEach(name => {
    log.error(`missing field/adjunct for ${name}`)
  })

  // These six vars, along with params, are passed into each API
  // contributor and then updated according to the contributor's return
  // value.
  let haveTerms = false
  let haveProps = false
  let chat: Record<string, any> = {} // inter-field communication
  let {metaTypes} = options // normally undefined
  let mine: Record<string, any> = {} // per-field intra-stage storage
  let sp: SP = mapToObj(() => [], CONSTRAINTS)

  // Utilities passed into each API contributor.
  // These will be merged with standard utils in runHook.
  const utils = {
    ...shortcuts,
    metaTypesInheritFrom,
    propParam,
    toTokens,
    toTerms,
  }

  for (const stage of STAGES) {
    for (const name of names) {
      const field = fields[name]
      let value = params[name]
      const slog = log.logger(`${name}/${stage}`)
      if (!field.api) {
        // Missing field.api only expected for adjunct fields.
        if (adjuncts.includes(name)) {
          // Another field will handle the value of this one.
        } else if (stage === 'contribute') {
          slog.error('missing field.api')
        }
        continue
      }
      if (!field.api[stage]) {
        // This is common... Most field.api structures will only include
        // one or two stages (and empty field.api object means "I'm not
        // adjunct, but don't complain about it")
        continue
      }
      if (stage !== 'initialize' && value === undefined) {
        // Only the initialize stage is allowed to run without a value in
        // params.
        if (stage === 'contribute') {
          slog.error('missing value')
        }
        continue
      }
      const stageProps = {
        chat,
        config,
        field,
        metaTypes,
        my: mine[name],
        name,
        options,
        params,
        sp,
        utils: {
          ...utils,
          log: slog,
        },
        value,
      }
      slog.debug?.('running stage', stageProps)
      const ret = runHook(field.api[stage]!, stageProps)
      if (ret !== undefined) {
        if (ret === null) {
          slog.debug?.('stage returned null, aborting')
          return null
        }
        slog.debug?.(`stage returned ${stableJson(ret)}`)

        // Update state from the returned object. If the returned object
        // contains all/any/excluded then these are concatenated to the
        // arrays on the sp object.
        ;({chat = chat, metaTypes = metaTypes, params = params, sp = sp} = ret)
        if ('my' in ret) mine[name] = ret.my
        for (const c of CONSTRAINTS) {
          push(sp[c], ret[c])
        }
        slog.debug?.('stage updated state', {
          chat,
          metaTypes,
          params,
          sp,
          mine,
        })

        // If the contributor doesn't return {haveProps: false} then assume
        // that haveProps should now be true.
        if (!haveProps && R.defaultTo(stage === 'contribute', ret.haveProps)) {
          slog.debug?.(`setting haveProps=true`)
          haveProps = true
        }

        // If the contributor returns {haveTerms: true} then set that.
        if (!haveTerms && ret.haveTerms) {
          slog.debug?.(`setting haveTerms=true`)
          haveTerms = true
        }
      }
    }
  }

  // Check for empty query
  if (haveTerms || (haveProps && params.depth !== 'fulltext')) {
    // Not an empty query
  } else if (!options.allowEmpty) {
    // Avoid calling the API unnecessarily. This is presented as empty
    // results rather than ALL POSSIBLE results.
    return null
  }

  // Merge preset params from config
  if (pre) {
    browser.log('merging preset params from config:', pre)
    if (pre.filterCollectionId) {
      if (sp.filterCollectionId !== undefined) {
        log.error("can't merge filterCollectionId")
      }
      sp.filterCollectionId = pre.filterCollectionId
    }
    CONSTRAINTS.forEach(c => {
      const prec = pre[c] // crutch for TS
      const arr: string[] =
        typeof prec === 'string' ? [prec.trim()].filter(Boolean)
        : Array.isArray(prec) ? prec
        : []
      if (arr.length) {
        if (c === 'any') {
          log.assert(!sp.any.length, "can't merge ANY terms, using pre.any")
          sp.any = [...arr]
        } else {
          push(sp[c], arr)
        }
      }
    })
  }

  // Boost selected results to the top.
  if (options.boost !== undefined) {
    push(options.boost ? sp.all : sp.excluded, [`${BOOST_FIELD_NAME}:true`])
  }

  // Sort terms for consistency (especially tests)
  CONSTRAINTS.forEach(c => {
    sp[c] = sp[c].filter(Boolean).sort()
  })

  // Drop empty arrays. This doesn't really matter because the API layer
  // will ignore empty parameter arrays, but it makes testing easier.
  sp = R.reject(
    value =>
      (typeof value === 'string' || Array.isArray(value)) && value.length === 0,
    sp,
  ) as SP

  log.debug?.(
    options.logName ? `(${options.logName})` : '',
    {options, params, tokens: options.tokens},
    '\n' + stableJson(sp),
  )
  return sp
}

const isRange = (
  x: unknown,
): x is {
  operator: '=' | '<' | '>' | '-' | ''
  exact?: number
  low?: number
  high?: number
} => typeof x === 'object' && x !== null && 'operator' in x

export function propParam(
  {
    exclusive,
    metaTypes,
    name,
    prop,
  }: {
    exclusive?: boolean
    metaTypes: string[]
    name: string
    prop: string
  },
  value: unknown,
) {
  if (!metaTypes) {
    log.error(`missing field.metaTypes for ${name}`)
    return
  }
  if (!prop) {
    log.error(`missing field.prop for ${name}`)
    return
  }
  if (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean'
  ) {
    if (value !== '') {
      return simpleTerm(metaTypes, prop, `${value}`)
    }
  } else if (Array.isArray(value)) {
    if (value.length) {
      return value.length === 1 ?
          simpleTerm(metaTypes, prop, `${value[0]}`)
        : multiTerm(
            metaTypes,
            prop,
            value.map(v => `${v}`),
          )
    }
  } else if (isRange(value)) {
    const {operator, low, high, exact} = value
    let min, max
    switch (operator) {
      case '=':
        min = max = exact
        break
      case '<':
        max = high
        break
      case '>':
        min = low
        break
      case '-':
        min = low
        max = high
        break
      case '':
        break
      default:
        log.error(`don't know ${name}.operator=${operator}`)
        return
    }
    if (min !== undefined && max !== undefined) {
      return rangeTerm(metaTypes, prop, min, max)
    }
  } else {
    // We were given a TYPE that we don't recognize, such as a null or an
    // object that doesn't conform to a range.
    log.error(`don't know ${name}=${JSON.stringify(value)}`)
    return
  }
  // We recognize the type of the argument, so no error, but the value was
  // falsy, such as an empty string or array.
  if (exclusive) {
    return hasValue(metaTypes, prop)
  }
}

export function hasValue(metaTypes: string[], prop: string) {
  return colonJoin(['has-value', 'strict', semiJoin(metaTypes), prop, ''])
}

export function simpleTerm(metaTypes: string[], prop: string, value: string) {
  // This cannot be strict because whereas:
  //
  //    Book:Authors:Aron Griffis
  //
  // returns a PageRange, none of the following do:
  //
  //    strict:Book:Authors:Aron Griffis
  //    strict:PageRange:Authors:Aron Griffis
  //    strict:Book;PageRange:Authors:Aron Griffis
  //
  // I do not understand why, though. :-(
  return colonJoin([semiJoin(metaTypes), prop, value])
}

export function multiTerm(metaTypes: string[], prop: string, values: string[]) {
  const value = semiJoin(values)
  // simpleTerm calls colonJoin, so don't do it here, otherwise colons will be
  // double-escaped.
  return `multiterm:${simpleTerm(metaTypes, prop, value)}`
}

export function rangeTerm(
  metaTypes: string[],
  prop: string,
  min: number | string,
  max: number | string,
) {
  const value = semiJoin([`${min}`, `${max}`])
  // simpleTerm calls colonJoin, so don't do it here, otherwise colons will be
  // double-escaped.
  return `range:${simpleTerm(metaTypes, prop, value)}`
}
