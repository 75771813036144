import * as tizra from 'tizra'

const log = tizra.logger('search/hooks')

/**
 * Run a config-defined hook with payload augmented by standard utilities.
 */
export function runHook<H extends (arg: any) => any>(
  hook: H,
  payload?: any,
): ReturnType<H> {
  return hook({
    ...payload,
    utils: {
      ...tizra,
      log, // should be overridden by caller
      ...payload?.utils,
    },
  })
}
