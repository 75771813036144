import {SparseField} from '../types'
import {logger} from 'tizra'

const log = logger('field-types/year')

interface YearRange {
  operator: '' | '=' | '>' | '<' | '-'
  exact?: string
  low?: string
  high?: string
}

export const year: SparseField<YearRange, false> = {
  defaultValue: {operator: ''},
  test: function (v) {
    return v.operator !== ''
  },
  // @ts-expect-error
  widget: 'year-range',
  mobileFilters: {
    abbrev: v => {
      // prettier-ignore
      switch (v.operator) {
        case '=': return v.exact
        case '>': return `from ${v.low}`
        case '<': return `until ${v.high}`
        case '-': return v.low + '-' + v.high
        default: log.warn('years.abbrev: operator is ' + v.operator)
      }
      return ''
    },
  },
  filterTags: {
    label: v => {
      // prettier-ignore
      switch (v.operator) {
        case '=': return 'year'
        case '>': return 'from'
        case '<': return 'until'
        case '-': return 'years'
        default: log.warn('years.label: operator is ' + v.operator)
      }
      return ''
    },
    abbrev: v => {
      // prettier-ignore
      switch (v.operator) {
        case '=': return v.exact
        case '>': return v.low
        case '<': return v.high
        case '-': return v.low + '-' + v.high
        default: log.warn('years.abbrev: operator is ' + v.operator)
      }
      return ''
    },
  },
  api: {
    contribute: ({field, value, utils: {all, propParam}}) =>
      all(propParam(field, value)),
  },
}
