/**
 * <object> hack implementation based on https://www.kizu.ru/nested-links/
 */
import {StrictReactNode} from 'quickstart/types'
import {createContext, useContext} from 'react'

const ObjectHackContext = createContext<{[k: string]: true}>({})

export const useObjectHack = (key: string, inner: StrictReactNode) => {
  const context = useContext(ObjectHackContext)
  return context[key] ?
      <object type="owo/uwu">{inner}</object>
    : <ObjectHackContext.Provider value={{...context, [key]: true}}>
        {inner}
      </ObjectHackContext.Provider>
}
