import * as R from 'rambdax'
import * as regexp from 'tizra'
import {escapeSemis, logger, semiJoin, truthy} from 'tizra'

const log = logger('search/terms')

// https://stackoverflow.com/a/17531638
const LUCENE_ENGLISH_STOP_WORDS = [
  'a',
  'an',
  'and',
  'are',
  'as',
  'at',
  'be',
  'but',
  'by',
  'for',
  'if',
  'in',
  'into',
  'is',
  'it',
  'no',
  'not',
  'of',
  'on',
  'or',
  'such',
  'that',
  'the',
  'their',
  'then',
  'there',
  'these',
  'they',
  'this',
  'to',
  'was',
  'will',
  'with',
]

const STOP_PATT = `(?:${LUCENE_ENGLISH_STOP_WORDS.join('|')})`

const isStopWord = (w: string) => LUCENE_ENGLISH_STOP_WORDS.includes(w)

// https://stackoverflow.com/a/69293235/347386
function AssignCtor<T extends object>() {
  return class {
    constructor(t: T) {
      Object.assign(this, t)
    }
  } as {new (t: T): T}
}

interface TokenProps {
  index: number
  length: number
  op: string
  phrase: string
  quotes: 0 | 1 | 2
  wild: '' | '*'
}

export class Token extends AssignCtor<TokenProps>() implements TokenProps {
  static parse(subInput: string, index: number) {
    let op = '',
      quotes: 0 | 1 | 2 = 0,
      phrase = subInput.replace(/\s+/g, ' '),
      wild: '' | '*' = ''
    if (phrase.startsWith('+') || phrase.startsWith('-')) {
      op = phrase[0]
      phrase = phrase.substring(1)
    }
    if (phrase.endsWith('*')) {
      wild = '*'
      phrase = phrase.substring(0, phrase.length - 1)
    }
    if (phrase.startsWith('"')) {
      quotes = 1
      phrase = phrase.substring(1)
      if (phrase.endsWith('"')) {
        quotes = 2
        phrase = phrase.substring(0, phrase.length - 1)
      }
    }
    return new Token({
      index,
      length: subInput.length,
      op,
      phrase,
      quotes,
      wild,
    })
  }

  clone(overrides?: TokenProps) {
    return new Token({...this, ...overrides})
  }

  merge(other: TokenProps) {
    // Return undefined for unmergeable tokens. This makes it easier for
    // caller to try a merge than detect the unmergeable cases ahead of
    // time.
    if (
      this.op ||
      other.op ||
      this.quotes ||
      other.quotes ||
      this.wild ||
      other.wild
    ) {
      log.debug?.("Token.merge: can't merge ops/quotes/wild")
      return
    }

    const [left, right] = [this, other].sort(t => t.index)
    const merged = Token.parse(left.phrase + ' ' + right.phrase, left.index)

    // Handle multiple spaces separating tokens in input.
    // This also obliterates intermediate tokens if called with
    // non-adjacent other, but caller shouldn't do that.
    merged.length = right.index + right.length - left.index

    return merged
  }

  toPattern() {
    const {op, phrase, wild} = this

    if (!log.assert(op !== '-', "can't convert negative token to regexp")) {
      return
    }

    const words = phrase
      .toLowerCase()
      .split(/\W+/)
      .filter(w => w.length)

    if (R.all(isStopWord, words)) {
      log.debug?.(`Token.toPattern: ${phrase} only consists of stop words`)
      return
    }

    const collapseStopWords = R.reduce<string, string[]>((words, w) => {
      if (!words.length || !isStopWord(w) || !isStopWord(R.last(words))) {
        words.push(w)
      }
      return words
    }, [])

    const patts = R.pipe(
      collapseStopWords,
      R.map(R.ifElse(isStopWord, R.always(`${STOP_PATT}*`), regexp.escape)),
    )(words)

    const wildPatt = wild ? '\\w*' : ''

    return `${patts.join('\\W+')}${wildPatt}`
  }

  toString(escape = false) {
    let {op, quotes, phrase, wild} = this
    let q = quotes ? '"' : ''

    if (escape) {
      // HACK to enable boolean negation by putting quotes around negated
      // terms.
      if (op === '-') {
        q = '"'
      }

      // Lowercase to avoid Lucene operators (AND/OR)
      phrase = phrase.toLowerCase()

      // Escape percent signs and colons in terms. Note this isn't full
      // encodeURIComponent() because that seems to introduce problems, for
      // example spaces encoded as %20 generate unexpected results.
      phrase = phrase.replace(/[%:]/g, c => '%' + c.charCodeAt(0).toString(16))

      // Escape backslashes and semi-colons in terms with backslash.
      phrase = escapeSemis(phrase)
    }

    if (q) {
      // Wildcard not allowed after quotes--it returns 500.
      wild = ''
    }

    return `${op}${q}${phrase}${q}${wild}`
  }
}

/**
 * Parse a raw string of terms from the user to Tokens.
 *
 * class Tokens extends Array would be lovely here, but subclassing Array
 * isn't available until ES2015, and Babel doesn't polyfill.
 * https://thejsguy.com/2017/09/21/subclassing-arrays-in-es2015.html
 */
const TOKEN_RE = regexp.tag('gisux')`
  (?:([-+])\s*)?    # optional leading operator
  (
    "[^"]*"         # quoted string
  | ".*             # unclosed quoted string
  | [^-+*\s]\S*     # unquoted word
  )
`
export function toTokens(s: string) {
  const tokens = []
  let m
  while ((m = TOKEN_RE.exec(s)) !== null) {
    log.debug?.('toTokens', m)
    tokens.push(Token.parse(`${m[1] || ''}${m[2]}`, m.index))
  }
  log.debug?.('toTokens', {s, tokens})
  return tokens
}

/**
 * Convert tokens, along with metaTypes and a property name, to a terms
 * string suitable for passing to the Tizra search API in the
 * all/any/excluded arrays.
 */
export function toTerms(
  tokens: Token[],
  _metaTypes: string | string[],
  propName: string,
  ...prefixes: string[]
) {
  const metaTypes = Array.isArray(_metaTypes) ? _metaTypes : [_metaTypes]
  const s = tokens.map(t => t.toString(true)).join(' ')
  if (s.indexOf('*') > -1) {
    prefixes.push('wildcard')
  }
  prefixes.push('full-text')
  const terms = `${prefixes.sort().join(':')}:${semiJoin(
    metaTypes,
  )}:${propName}:${s}`
  log.debug?.('toTerms', {tokens, terms})
  return terms
}

/**
 * Convert tokens to a regular expression useful for highlighting.
 * The regexp will be unanchored, and case-insensitive by default.
 */
export function toRegExp(tokens: Token[], flags = 'i') {
  const patts = R.piped(
    tokens,
    R.reject(t => t.op === '-'),
    R.sortBy(t => -t.length),
    R.map(t => t.toPattern()),
    patts => patts.filter(truthy),
  )
  const re =
    patts.length ? regexp.tag(flags + 'u')`\b(?:${patts.join('|')})\b` : null
  log.debug?.('toRegExp', {tokens, patts, re})
  return re
}

/**
 * Make a highlighter for the given terms string or tokens.
 * The returned highlighter can be called with a snippet to highlight, and
 * receive back an array of objects {highlight: true/false, content}.
 */
const _highlighter = (stringOrTokens: string | Token[]) => {
  const tokens =
    Array.isArray(stringOrTokens) ? stringOrTokens : toTokens(stringOrTokens)
  const re = toRegExp(tokens, 'ig')
  return (s: string) => {
    let i = 0,
      splits = []
    for (let m; re && (m = re.exec(s)) !== null; i = m.index + m[0].length) {
      log.debug?.('highlighter', m)
      if (m.index > i) {
        splits.push({
          content: s.substring(i, m.index),
          highlight: false,
        })
      }
      splits.push({
        content: s.substring(m.index, m.index + m[0].length),
        highlight: true,
      })
    }
    if (i < s.length) {
      splits.push({
        content: s.substring(i),
        highlight: false,
      })
    }
    return splits
  }
}

const simpleMemoize = <T extends (...args: any) => any>(fn: T): T => {
  let lastArg: any = {},
    lastResult: ReturnType<T>
  const memoizedFn = (...args: any) => {
    if (args[0] !== lastArg) {
      lastResult = fn(...args)
      lastArg = args[0]
    }
    return lastResult
  }
  return memoizedFn as T
}

export const highlighter = simpleMemoize(_highlighter)
