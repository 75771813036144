import * as R from 'rambdax'
import {useEffect, useReducer, useRef} from 'react'
import {useEqualBy} from './useEqualBy'

interface State {
  observer?: IntersectionObserver | ResizeObserver
  node?: Element | null
  entry?: IntersectionObserverEntry | ResizeObserverEntry
}

type Action =
  | {type: 'observer'; observer: Exclude<State['observer'], undefined>}
  | {type: 'node'; node: Exclude<State['node'], undefined>}
  | {type: 'entry'; entry: Exclude<State['entry'], undefined>}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'observer': {
      const {observer} = action
      if (state.observer) {
        state.observer.disconnect()
      }
      if (state.node) {
        observer.observe(state.node)
      }
      return {...state, observer}
    }

    case 'node': {
      const {node} = action
      if (state.node === node) {
        return state
      }
      if (state.observer) {
        state.observer.disconnect()
        if (node) {
          state.observer.observe(node)
        }
      }
      return {...state, node}
    }

    case 'entry': {
      const {entry} = action
      if (state.entry === entry) {
        return state
      }
      return {...state, entry}
    }

    default:
      throw new Error()
  }
}

/**
 * Use a callback ref with an observer (IntersectionObserver, ResizeObserver).
 */
export function useObserver(
  Observer: typeof IntersectionObserver,
  options?: IntersectionObserverInit & {enabled?: boolean},
): [IntersectionObserverEntry | undefined, (node?: Element | null) => void]
export function useObserver(
  Observer: typeof ResizeObserver,
  options?: {enabled?: boolean},
): [ResizeObserverEntry | undefined, (node?: Element | null) => void]
export function useObserver(
  Observer: typeof IntersectionObserver | typeof ResizeObserver,
  {enabled = true, ...options}: any = {},
) {
  options = useEqualBy(R.equals, options)
  const [state, dispatch] = useReducer(reducer, {})

  useEffect(() => {
    if (!enabled) return
    const observer = new Observer(entries => {
      if (entries.length) {
        dispatch({type: 'entry', entry: entries[entries.length - 1]})
      }
    }, options)
    dispatch({type: 'observer', observer})
    return () => {
      observer.disconnect()
    }
  }, [Observer, enabled, options])

  // This callback ref, as opposed to a normal useRef, will be called whenever
  // the node comes or goes. That way we can update the observer accordingly.
  const callbackRef = useRef((node: Element | null) => {
    dispatch({type: 'node', node})
  }).current

  return [state.entry, callbackRef]
}
