import {dom} from 'tizra'
import {SearchConfigWithSparseFields, SparseConfig} from './types'

const common = {
  bookmarkable: true,
  defaultOpen: true,
  dom: {
    hideFooter: () =>
      Array.from(document.querySelectorAll('footer'))
        .filter(dom.isVisible)
        .map(dom.hide),
    showFooter: (hidden: dom.HiddenObject[]) => hidden.forEach(dom.show),
  },
  quickSearchFields: ['Title', 'Authors', 'Abstract'],
} satisfies SparseConfig

const browse = {
  ...common,

  mode: 'browse',

  metaTypes: {
    metadata: ['Book'],
  },

  depths: ['metadata'],

  fields: {
    terms: {
      type: 'terms',
    },
    sort: {
      type: 'sort',
      options: [
        {
          value: 'title',
          text: 'Title',
        },
        {
          value: 'date-descending',
          text: 'Recent',
        },
      ],
      defaultValue: 'title',
    },
    start: {
      type: 'start',
    },
    page: {
      defaultValue: 100,
    },
    filterLicenses: {
      type: 'filterLicenses',
    },
  },

  order: [], // don't show any fields

  sorting: {
    date: 'publishedDate',
    title: 'Title',
    relevance: '',
  },
} satisfies SearchConfigWithSparseFields

const search = {
  ...common,

  mode: 'search',

  metaTypes: {
    metadata: ['Book'],
    toc: ['toc-entry'],
    fulltext: ['PdfPage'],
  },

  depths: ['metadata', 'toc', 'fulltext'],

  fields: {
    terms: {
      type: 'terms',
    },
    sort: {
      type: 'sort',
      options: [
        /*
          {
            text: 'Date',
            value: 'date-descending',
          },
          */
        {
          text: 'Relevance',
          value: 'relevance',
        },
        {
          text: 'Title & Page',
          value: 'appearance',
        },
      ],
      defaultValue: 'relevance',
    },
    start: {
      type: 'start',
    },
    page: {
      defaultValue: 40,
    },
    filterLicenses: {
      type: 'filterLicenses',
    },
  },

  // Leave depth in order, so it will be shown this way if customer
  // config defines it.
  order: ['terms', 'depth'],

  sorting: {
    appearance: 'Title',
    date: 'publishedDate',
    relevance: '',
  },

  autoComplete: {
    enablePhrases: true,
    enableStops: false,
    minChars: 3,
    maxResults: 8,
    delay: 250,
    unquotedPhraseHack: true,
  },
} satisfies SearchConfigWithSparseFields

const quick: SearchConfigWithSparseFields = {
  ...search,
  mode: 'quick',
}

export const DEFAULTS = {
  browse,
  quick,
  search,
} satisfies Record<string, SearchConfigWithSparseFields>
