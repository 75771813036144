import {SparseField} from '../types'
import * as R from 'rambdax'

const OPTIONS = [
  {value: 'metadata', text: 'Headings + metadata'},
  {value: 'fulltext', text: 'Full-text'},
]

export const depth: SparseField<string, false> = {
  label: 'Depth',
  options: OPTIONS,
  defaultValue: 'metadata',
  abbrev: (v, _f) => {
    return {fulltext: 'full-text'}[v] || v
  },
  advancedSearch: {
    label: '',
    widget: 'radio-inline',
    // sort defaultValue to the front in advancedSearch.
    // we can't do this at the top level, because toggle widgets depend
    // on the order of the options.
    options: ({defaultValue, options}) =>
      R.piped(
        options as typeof OPTIONS,
        R.partition(R.propEq(defaultValue, 'value')),
        R.chain(R.identity),
      ),
  },
  desktopFilters: {
    // @ts-expect-error not implemented yet
    widget: 'toggle',
  },
  mobileFilters: {
    // @ts-expect-error not implemented yet
    widget: 'toggle',
  },
  filterTags: {
    // simple "full-text" instead of "depth: full-text"
    label: '',
    // consistent "full-text" filter tag regardless of default value
    test: v => v === 'fulltext',
  },
}
