import {IS_TEST, logger} from 'tizra'

const log = logger('isomorphic-observer')

class NullObserver {
  observe() {}
  unobserve() {}
  disconnect() {}
}

export function isomorphicObserver(
  observerName: 'IntersectionObserver',
): typeof IntersectionObserver
export function isomorphicObserver(
  observerName: 'ResizeObserver',
): typeof ResizeObserver
export function isomorphicObserver(
  observerName: 'IntersectionObserver' | 'ResizeObserver',
) {
  if (typeof window === 'undefined') {
    return NullObserver
  }
  if (!window[observerName]) {
    if (!IS_TEST) {
      log.warn(`window object missing ${observerName}`)
    }
    return NullObserver
  }
  return window[observerName]
}
