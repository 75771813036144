import {FormState} from 'final-form'
import {
  SearchConfig,
  SearchDepth,
  SearchParamsOptions,
} from 'quickstart/lib/search/config'
import searchParams, {
  BOOST_FIELD_NAME,
} from 'quickstart/lib/search/search-params'
import * as R from 'rambdax'
import {useCallback, useEffect, useMemo, useReducer, useRef} from 'react'
import {SearchResult, SearchResultTypes, logger, shallowIdentical} from 'tizra'
import {ValueOf} from 'type-fest'
import {useInfiniteApi} from './useApi'
import {useEqualBy} from './useEqualBy'
import {UseSearchConfigReturn} from './useSearchConfig'

const log = logger('useSearch')

const reducerSearchConfigProps = [
  'config' as const,
  'configured' as const,
  'defaultParams' as const,
]

type ReducerSearchConfig = Pick<
  UseSearchConfigReturn,
  (typeof reducerSearchConfigProps)[number]
>

const actions = {
  init: (searchConfig: ReducerSearchConfig) => ({
    type: 'init' as const,
    searchConfig,
  }),
  navigate: (params: Record<string, any>) => ({
    type: 'navigate' as const,
    params,
  }),
  update: (params: Record<string, any>) => ({
    type: 'update' as const,
    params,
  }),
}

interface SearchReducerState extends ReducerSearchConfig {
  params: Record<string, any>
}

const searchReducer = log.trace(
  'searchReducer',
  (state: SearchReducerState, action: ReturnType<ValueOf<typeof actions>>) => {
    switch (action.type) {
      case 'init': {
        const {defaultParams} = action.searchConfig
        let newParams
        if (!R.equals(defaultParams, state.defaultParams)) {
          // Only re-initialize params that haven't been modified by the user.
          const reInitialParams = R.pipe(
            R.toPairs,
            R.filter(
              ([k]) =>
                !state.params?.[k] ||
                R.equals(state.params[k], state.defaultParams[k]),
            ),
            R.fromPairs,
          )(defaultParams)
          newParams = {
            ...state.params,
            ...reInitialParams,
          }
        }
        return {
          ...state,
          ...action.searchConfig,
          params:
            newParams && !R.equals(newParams, state.params) ?
              newParams
            : state.params,
        }
      }

      case 'navigate': {
        const newParams = {...state.defaultParams, ...action.params}
        return R.equals(newParams, state.params) ? state : (
            {...state, params: newParams}
          )
      }

      case 'update': {
        const newParams = {...state.params, ...action.params}
        return R.equals(newParams, state.params) ? state : (
            {...state, params: newParams}
          )
      }

      default:
        return state
    }
  },
)

export interface UseSearchReturn<T = SearchResultTypes> {
  config: SearchConfig
  configured: boolean
  defaultParams: any
  depths: SearchDepth[]
  fetchNextPage: (() => void) | null
  hasNextPage: boolean
  isFetching: boolean
  lastResults: SearchResult<T>[] | null
  onChange: (state: FormState<any, any>) => void
  onSubmit: (values: any) => void
  params: any
  results: SearchResult<T>[] | null
  size: number | null
  update: (params: any) => void
}

/**
 * React hook for calling the search API when component mounts and when query
 * changes. This is the equivalent of the old useSearchRoutine, without the
 * routines...
 */
export const useSearch = <T = SearchResultTypes>(
  // combined result of useSearchConfig and useSearchParams
  {
    urlParams,
    setUrlParams,
    ..._searchConfig
  }: UseSearchConfigReturn & {urlParams?: any; setUrlParams?: any},
  {
    boostable = false,
    enabled = true,
    ...options
  }: Omit<SearchParamsOptions, 'boost'> & {
    boostable?: boolean
    enabled?: boolean
  } = {},
): UseSearchReturn<T> => {
  // Pass initial state to reducer for SSR, especially params and defaultParams,
  // since it won't run effects.
  const searchConfig = useEqualBy(
    shallowIdentical,
    R.pick(reducerSearchConfigProps, _searchConfig),
  )
  const [{config, configured, defaultParams, params}, dispatch] = useReducer(
    searchReducer,
    {
      ...searchConfig,
      params: {...searchConfig.defaultParams, ...urlParams},
    },
  )

  // Handle updates to defaultParams as promises complete in the config bringup.
  // This won't loop because of useEqualBy(searchConfig) above.
  useEffect(() => {
    dispatch(actions.init(searchConfig))
  }, [dispatch, searchConfig])

  // Handle updates to urlParams as the user navigates (browser back and forward).
  useEffect(() => {
    dispatch(actions.navigate(urlParams))
  }, [dispatch, urlParams])

  // Bookmarkable searches (the normal case) push updates to the URL, which
  // causes a location/router update, and we end up back in this hook with new
  // urlParams. Non-bookmarkable searches will lack setUrlParams, so updates go
  // directly to dispatch.
  const update = useCallback(
    (params: any) =>
      setUrlParams ?
        setUrlParams({...urlParams, ...params})
      : dispatch(actions.update(params)),
    [dispatch, setUrlParams, urlParams],
  )

  // Specialized onChange for React Final Form's FormSpy.
  const onChange = useCallback(
    ({dirty, values}: any) => {
      if (dirty) {
        update(values)
      }
    },
    [update],
  )

  // Specialized onSubmit for React Final Form.
  const onSubmit = useCallback((values: any) => update(values), [update])

  // React Query doesn't support an unknown number of calls without separating
  // them into components, see
  // https://github.com/tannerlinsley/react-query/discussions/493 but in
  // practice there's an upper limit on depths, so we can fake it. There are
  // three at the moment (metadata, toc, fulltext) so we'll leave room for
  // a fourth and assert against overflow.
  const depths = useMemo<SearchConfig['depths']>(
    () => (params?.depth ? [params.depth] : config.depths),
    [config, params],
  )
  log.assert(
    depths.length < 5,
    `whoa, we have ${depths.length} depths, but we're limited to 4`,
  )

  // Convert simple key-value field params to any/all/excludes trios for the
  // search API.
  options = useEqualBy(R.equals, options)
  const sps = useMemo(() => {
    if (!configured || !params) return []
    const boosts =
      (
        boostable &&
        // Check that we're doing relevance sorting. Normally this means
        // params.sort=='relevance' but it resolves via config.sorting so let's
        // check that.
        !config.sorting[params.sort] &&
        // Does the special field exist on this site?
        config.fieldDefs.some(def => def.name === BOOST_FIELD_NAME)
      ) ?
        [true, false]
      : [undefined]
    return depths
      .flatMap(depth =>
        boosts.map(
          boost =>
            (!boost || depth === 'metadata') &&
            searchParams(
              config,
              {...params, depth},
              {
                allowEmpty: true,
                logName: `${config.mode}.useSearch.${depth}`,
                paging: true,
                sort: true,
                boost,
                ...options,
              },
            ),
        ),
      )
      .filter(Boolean)
  }, [boostable, config, configured, depths, options, params])

  // Add ?fields= to reduce the response size.
  const spsWithFields = useMemo(() => sps.map(withSearchFields), [sps])

  // Make sure to call the hook all four times regardless, following the rules
  // of hooks.
  const queries = [
    useInfiniteApi.search(enabled && spsWithFields[0]),
    useInfiniteApi.search(enabled && spsWithFields[1]),
    useInfiniteApi.search(enabled && spsWithFields[2]),
    useInfiniteApi.search(enabled && spsWithFields[3]),
  ].slice(0, spsWithFields.length)

  // Combine the results into something that works like a single
  // useInfiniteApi result.
  const hasNextPage = queries.some(q => q.hasNextPage)
  const isFetching = queries.some(q => q.isFetching)
  const fetchNextPage = queries.find(q => q.hasNextPage)?.fetchNextPage || null

  // Concat data from each depth, stopping when we reach a depth that is still
  // fetching or could fetch more. That way we aren't visually inserting, for
  // example, books prior to toc results that are already on screen.
  const _data: unknown[] = []
  for (const q of queries) {
    if (q.data?.pages) {
      _data.push(...q.data.pages)
    }
    if (q.hasNextPage || q.isFetching) {
      break
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = useMemo(() => _data, [_data.length, _data[0]])

  // Flatten data into results for easier rendering.
  const results = useMemo<SearchResult<T>[] | null>(
    () =>
      data.length ?
        (R.pipe(
          R.chain(R.propOr([], 'result')),
          // Drop null entries. These happen when the Lucene index and the
          // content are out of sync.
          R.filter(Boolean),
          // @ts-expect-error
        )(data) as SearchResult<T>[])
      : configured && enabled && !isFetching ? []
      : null,
    [configured, data, enabled, isFetching],
  )

  // Return null size until something is non-zero, or until all are reporting.
  // This allows the caller to know definitively that size=0 is a final answer.
  // When we've reached the bottom, refer directly to the length of the combined
  // results since there might have been null entries, and we might as well get
  // the final size right since we know it.
  const size =
    results &&
    (!hasNextPage && !isFetching ?
      results.length
    : R.pipe(
        R.map(R.path(['data', 'pages', 0, 'size'])),
        // @ts-expect-error
        sizes => R.sum(sizes) || (R.any(R.isNil, sizes) ? null : 0),
      )(queries))

  // Results will be null whenever calling the API with a fresh search, meaning
  // initial load and also if fields are modified. Track the most recently
  // retrieved results so caller can transition smoothly instead of flashing
  // empty.
  const lastResultsRef = useRef<SearchResult<T>[] | null>(null)
  const lastResults = (lastResultsRef.current =
    results || lastResultsRef.current)

  const search = {
    hasNextPage,
    config,
    configured,
    defaultParams,
    depths,
    fetchNextPage,
    isFetching,
    lastResults,
    onChange,
    onSubmit,
    params,
    results,
    size,
    update,
  }

  return search
}

function withSearchFields(params: any) {
  return {
    fields: [
      'book-tizra-id', // PdfPage, toc-entry
      'level', // toc-entry
      'id', // toc-entry
      'logical-page', // toc-entry
      'logical-page-number', // PdfPage
      'meta-type', // Book results have this
      'metaType', // PageRange results have this?
      'name',
      'page-number', // PdfPage, toc-entry
      'pages', // PageRange
      'parent', // PageRange
      'parsed-props',
      'pdf-source-file-name',
      'snippet',
      'title', // toc-entry
      'tizra-id',
      'url-id',
    ],
    ...params,
  }
}
