import {partitions} from 'tizra'
import {SparseField} from '../types'

const STATES = {
  AK: 'Alaska',
  AL: 'Alabama',
  AR: 'Arkansas',
  AZ: 'Arizona',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DC: 'District of Columbia',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  IA: 'Iowa',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  MA: 'Massachusetts',
  MD: 'Maryland',
  ME: 'Maine',
  MI: 'Michigan',
  MN: 'Minnesota',
  MO: 'Missouri',
  MS: 'Mississippi',
  MT: 'Montana',
  NC: 'North Carolina',
  ND: 'North Dakota',
  NE: 'Nebraska',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NV: 'Nevada',
  NY: 'New York',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VA: 'Virginia',
  VT: 'Vermont',
  WA: 'Washington',
  WI: 'Wisconsin',
  WV: 'West Virginia',
  WY: 'Wyoming',
}

const valueTerms = (values: string[]): string[] =>
  values
    .flatMap(v => {
      const state = STATES[v as keyof typeof STATES] || ''
      return [`"state ${v}"`, state && `"${state}"`]
    })
    .filter(Boolean)
    .map(s => s.toLowerCase())

export const apaState: SparseField<string, true> = {
  label: 'State',
  options: Object.entries(STATES).map(([value, text]) => ({value, text})),
  abbrev: true,
  sort: true,
  filterTags: {
    label: 'state',
  },
  api: {
    before: ({value}) => {
      const stateTerms = valueTerms(value)
      if (stateTerms.length) {
        // Tell the terms field type that we have something to contribute, so that
        // terms doesn't abort toc and full-text searches.
        return {chat: {handWavyTerms: true}, my: stateTerms}
      }
    },
    contribute: ({my, params: {depth}, utils: {all, toTerms, toTokens}}) => {
      if (my?.length) {
        const stateTerms: string[] = my
        const tokens = toTokens(stateTerms.join(' '))
        switch (depth) {
          case 'fulltext':
            return all(toTerms(tokens, '', 'pageFullText'))
          case 'toc':
            return // will be handled in "after" stage below
          default:
            return null // abort metadata search
        }
      }
    },
    after: ({my, params: {depth}, sp, utils: {log, toTerms, toTokens}}) => {
      if (depth === 'toc' && my?.length) {
        const stateTerms: string[] = my
        const [tocAllTitles, tocTitle, all] = partitions(sp.all, [
          q => q.includes(':toc-all-titles:'),
          q => q.includes(':toc-title:'),
        ])
        const any = [...sp.any]

        if (!tocAllTitles.length) {
          // No existing toc-all-titles constraint, so we can create a new one
          // with all state terms optional. One will be required to match for
          // this to be satisfied. (We get this far because of handWavyTerms.)
          // full-text:strict:toc-entry:toc-all-titles:${stateTerms.join(' ')}
          const tokens = toTokens(stateTerms.join(' '))
          all.push(toTerms(tokens, 'toc-entry', 'toc-all-titles', 'strict'))
        } else if (tocAllTitles.length > 1) {
          // can't move if there's more than one.
          log.error(`unexpected tocAllTitles`, tocAllTitles)
        } else if (any.length) {
          // can't move if there are already any constraints.
          log.error(`sp.any already has values`, any)
        } else {
          // toc-all-titles constraint, with existing plus signs, moves from all
          // to any so that it can accommodate any of the requested states,
          // including abbreviations and full names.
          stateTerms.forEach(term => {
            any.push(`${tocAllTitles[0]} +${term}`)
          })
        }

        // toc-title constraint adds all the state variations as possible
        // matches for the leaf entry to increase relevancy.
        tocTitle.forEach(tt => {
          all.push(`${tt} ${stateTerms.join(' ')}`)
        })

        return {
          sp: {...sp, all, any},
        }
      }
    },
  },
}
