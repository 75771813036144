import * as reactRouterDom from 'react-router-dom'
import {logger} from 'tizra'
import {URLish} from 'tizra/urlish'
import {useBlockContext} from './useBlockContext'
import {useHydration} from './useHydration'

const log = logger('useLocation')

export const useLocation = () => {
  const contextLocation = useBlockContext('location')
  let before: reactRouterDom.Location
  try {
    before = new URLish(contextLocation)
  } catch (e) {
    log.error(
      `failed to build URL from context location (${contextLocation})`,
      e,
    )
    before = new URLish('/urlish-location-fail/')
  }
  const after = reactRouterDom.useLocation()
  return useHydration(before, after)
}
