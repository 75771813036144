import {SparseField} from '../types'

export const start: SparseField<number, false> = {
  defaultValue: 0,
  adjuncts: ['page'],
  api: {
    contribute: ({options: {paging}, params, sp}) => {
      const {start, page} = params as {start: number; page: number}
      if (paging) {
        return {
          sp: {...sp, ...(start ? {start} : {}), ...(page ? {page} : {})},
          haveProps: false,
        }
      }
    },
  },
}
