import {filterLicenses} from './filterLicenses'
import {prop} from './prop'
import {terms} from './terms'
import {depth} from './depth'
import {sort} from './sort'
import {start} from './start'
import {titleGroup} from './titleGroup'
import {year} from './year'
import {apaState} from './apaState'

const defaultFieldTypes = {
  apaState,
  depth,
  filterLicenses,
  prop,
  sort,
  start,
  terms,
  titleGroup,
  year,
}

export default defaultFieldTypes
