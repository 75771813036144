import * as R from 'rambdax'
import {takeWhile} from './functional'
import {isTocEntry, SearchResultTypes, TocEntry} from './types'

const BY_PAGE_NUMBER = new WeakMap<TocEntry[], number[]>()

const getOrCreatePageNumberCache = (toc: TocEntry[]): number[] => {
  let byPageNumber = BY_PAGE_NUMBER.get(toc)
  if (!byPageNumber) {
    byPageNumber = []
    toc.forEach((entry, i) => {
      byPageNumber![entry.pageNumber] = i // last one wins
    })
    BY_PAGE_NUMBER.set(toc, byPageNumber)
  }
  return byPageNumber
}

const BY_ENTRY_ID = new WeakMap<TocEntry[], Record<string, number>>()

const getOrCreateEntryIdCache = (toc: TocEntry[]): Record<string, number> => {
  let byEntryId = BY_ENTRY_ID.get(toc)
  if (!byEntryId) {
    byEntryId = {}
    toc.forEach((entry, i) => {
      byEntryId![entry.id] = i
    })
    BY_ENTRY_ID.set(toc, byEntryId)
  }
  return byEntryId
}

export const tocHierarchyForPageNumber = (
  toc: TocEntry[],
  pageNumber: number,
) => {
  // Ignore tocs with length less than two because a TOC with only one entry is
  // useless for titling. Typically we see this when a PDF is ingested with no
  // proper bookmarks, then it only has a single TOC entry which is the entire
  // book (and often titled by the PDF filename)
  if (toc.length < 2) {
    return []
  }

  // Keep a cache to avoid O(n^2)
  const byPageNumber = getOrCreatePageNumberCache(toc)

  // There might be no entries for the current page, so search backward 'til we
  // find one.
  let entryIndex
  for (let p = pageNumber; p > 0 && entryIndex === undefined; p--) {
    entryIndex = byPageNumber[p]
  }

  // This should never happen...
  if (entryIndex === undefined) {
    return []
  }

  // Iterate in reverse so we can trim from the end of entries until we find the
  // leaf entry for this page so we can walk upwards through the levels.
  let level = Infinity
  let ancestry: TocEntry[] = []
  for (
    let i = entryIndex;
    i >= 0 && (level > 0 || toc[i].pageNumber === pageNumber);
    i--
  ) {
    // Collect ALL the entries that land on the specific page number, resetting
    // the level each time. Once we have proceeded to an earlier page, collect
    // entries by decreasing levels.
    const entry = toc[i]
    if (entry.pageNumber === pageNumber || entry.level < level) {
      ancestry.push(entry)
      level = entry.level
    }
  }
  ancestry.reverse()

  // Find the min level within the ancestry that has a collision.
  // This implies the max level we can return without a collision.
  const collisionLevel = R.piped(
    ancestry,
    R.countBy(entry => `${entry.level}`), // keys are strings because JS
    R.filter((count: number, _: string) => count > 1), // might be empty object
    R.keys,
    R.map(parseInt), // might be empty list
    R.apply(Math.min), // might be Infinity
  )

  return takeWhile((x: TocEntry) => x.level < collisionLevel)(ancestry)
}

export const tocHierarchyForEntry = (toc: TocEntry[], entry: TocEntry) => {
  // Keep a cache to avoid O(n^2)
  const byEntryId = getOrCreateEntryIdCache(toc)
  const entryIndex = byEntryId[entry.id]

  // This should never happen...
  if (entryIndex === undefined) {
    return []
  }

  let ancestry = []
  let level = Infinity
  for (let i = entryIndex; i >= 0 && level > 0; i--) {
    const entry = toc[i]
    if (entry.level < level) {
      ancestry.push(entry)
      level = entry.level
    }
  }
  ancestry.reverse()

  return ancestry
}

export const tocHierarchy = (toc?: TocEntry[], metaObj?: SearchResultTypes) => {
  if (metaObj && toc) {
    if (isTocEntry(metaObj)) {
      return tocHierarchyForEntry(toc, metaObj)
    }
    if ('pageNumber' in metaObj) {
      return tocHierarchyForPageNumber(toc, metaObj.pageNumber)
    }
  }
}
