import {SparseField} from '../types'

export const filterLicenses: SparseField<boolean, false> = {
  defaultValue: false,
  api: {
    contribute: ({value, sp}) => {
      if (value) {
        return {
          sp: {...sp, filterLicenses: true},
          haveProps: false,
        }
      }
    },
  },
}
